.nav-link{
    position: relative;
    @media (min-width: 768px){
        &:hover{
            &:after{
                content: "";
                @apply absolute bottom-0 left-1/2 h-12 w-12 -translate-x-1/2 translate-y-1/2 rounded-full bg-sunrise;
            }
        }
    }
}

#nav-overlay{
    @media (max-width: 768px){
        display: none !important;
    }
}

.nav-subnav {
    // @apply flex shadow-sm;
    background-image: url("../img/circle-pattern-overlay.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    @media(min-width: 768px){
        @apply -translate-x-1/2;
        }
}

li {
    @apply font-[500];
}

@media (max-width: 768px){
    .nav-sublink, .nav-link{
        &::after{
            content: "";
            height: 32px;
            width: 32px;
            background-position: 50%;
            background-repeat: no-repeat;
        }
    }
    .nav-link{
        &:after{
            background-image: url("../img/chevron.svg");
            // transform: rotate(-90deg);
        }
    }
    .nav-sublink{
        @apply font-roboto-slab;
        &:after{
            background-image: url("../img/chevron-small.svg");
            transform: rotate(90deg);
        }
    }
}