.btn {
    @apply inline-flex cursor-pointer appearance-none items-center justify-center gap-3 rounded-12 border-2 p-4 text-lg font-semibold leading-none text-jet no-underline;

    &:hover {
        @apply transition-all ease-in-out;
        &:not(.btn--border) {
            @apply bg-white;
        }
    }

    &:active {
        @apply shadow-md;
    }

    &--malachite {
        @apply border-malachite bg-malachite;
    }

    &--rose-pink {
        @apply border-rose-pink bg-rose-pink;
    }

    &--sky-blue {
        @apply border-sky-blue bg-sky-blue;
    }

    &--sunrise {
        @apply border-sunrise bg-sunrise;
    }

    &--orange-peel {
        @apply border-orange-peel bg-orange-peel;
    }

    &--cotton-candy {
        @apply border-cotton-candy bg-cotton-candy;
    }

    &--white {
        @apply border-white bg-white;
        &:hover {
            @apply border-jet;
        }
        &.btn--border {
            @apply border-jet;
            &:hover {
                @apply bg-jet text-white;
            }
        }
    }

    &--disabled {
        @apply pointer-events-none select-none border-white bg-white opacity-50;
    }

    &--small {
        @apply p-[0.625rem] text-base mb-4 ;
    }

    &--large {
        @apply py-6 font-roboto-slab text-2xl font-semibold;
    }

    &--border {
        &:not(:hover) {
            @apply bg-white;
        }
    }
    &-banner{
         @apply p-[0.625rem] text-base my-2 ;
        @screen md{
            @apply inline-flex cursor-pointer appearance-none items-center justify-center gap-3 rounded-12 border-2 p-4 text-lg font-semibold leading-none text-jet no-underline;
        }
     }

    &--arrow {
        background-image: url("../img/arrow-right.svg");
        padding-right: 2.5rem;
        background-repeat: no-repeat;
        background-position: 90%;
    }
}
