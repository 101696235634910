html {
    font-size: $font-size;
    font-family: $font-family;
    // background: $background-color;
    color: $font-color;
    line-height: 1.4;
}

body {
    margin: auto;
}

@layer base {
    .container {
        @apply mx-auto w-[calc(100%-2.5rem)] xl:w-[calc(100%-5rem)] xl:max-w-[81.25rem];
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply mb-[1em] font-roboto-slab font-medium leading-none;
    }

    h1 {
        @apply mb-8 text-4xl md:text-5xl;
        &:only-child {
            @apply mb-0;
        }
    }
    h2 {
        @apply text-3xl;
    }
    h3 {
        @apply text-2xl;
    }

    p {
        @apply mb-5 text-lg;
        &:last-child {
            @apply mb-0;
        }
    }
    ol {
        @apply mb-5 ml-7 list-decimal;
        &:last-child {
            @apply mb-0;
        }
    }
}

.image {
    display: inline-block;
    height: 100px;
    width: 100px;
    // background-image: url('~assets/example.png');
    background-size: cover;
}

fieldset {
    margin-bottom: 1rem;
}

label,
legend {
    @apply block text-base font-semibold leading-none;
}

.text-link {
    @apply text-blue-crayola underline decoration-2 transition-all;
    &:hover {
        @apply decoration-4;
    }
}

a {
    .rte &:not(.btn) {
        @extend .text-link;
    }
}
.white-card{
    @apply border border-jet;
    border-width:2px; 
}
iframe{
    max-width: 100%
}
#close{
    @screen md{
        @apply inline-flex ;
        vertical-align:middle;
    }
    vertical-align: middle;
    display: inline-flex;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&display=swap");
