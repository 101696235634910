blockquote {
    // @apply border-l-[15px] border-black px-8;
    @apply relative mb-6 font-roboto-slab text-2xl font-medium;

    &:last-child {
        @apply mb-0;
    }

    &:before,
    &:after {
        @apply absolute font-roboto-slab text-6xl leading-[0] text-inherit;
    }

    &:before {
        @apply top-0 inline-block content-["“"];
        transform: translate(-24px, 24px);
    }

    &:after {
        @apply bottom-0 content-["”"];
        transform: translateX(10px);
    }
}
