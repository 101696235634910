.form-instruction-orange{
      gap: 1.75rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 1.5rem;
      &::before {

        content: "";

        background-color: #faa123;
      
        flex-shrink: 0;
      
        border-radius: 9999px;
      
        width: 0.5rem;
      }
  }

.form-instruction-green{
    gap: 1.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.5rem;
    &::before {

      content: "";

      background-color: #0ED145;
    
      flex-shrink: 0;
    
      border-radius: 9999px;
    
      width: 0.5rem;
    }
}

.form-instruction-red{
  gap: 1.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.5rem;
  &::before {

    content: "";

    background-color: #FF2200;
  
    flex-shrink: 0;
  
    border-radius: 9999px;
  
    width: 0.5rem;
  }
}

blockquote{
  position: relative;
  margin-bottom: 1.5rem;
  font-family: 'Roboto Slab', serif;
  font-size: 1.5rem;
  font-weight: 500;

  &::before {
    top: 0px;
  display: inline-block;
  --tw-content: "“";
  content: var(--tw-content);
  transform: translate(-24px, 24px);
  }

  &::after{
    bottom: 0px;
    --tw-content: "”";
    content: var(--tw-content);
    transform: translateX(10px);
  }
}



