.input-validation-error {
    @apply border-red ;
}
.field-validation-error {
    @apply mt-4 flex w-full items-center gap-1 rounded-xl bg-red/5 px-4 py-2 text-red;
    &:before {
        content: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 15.8545C10.2833 15.8545 10.521 15.7585 10.713 15.5665C10.905 15.3745 11.0007 15.1372 11 14.8545C11 14.5712 10.904 14.3335 10.712 14.1415C10.52 13.9495 10.2827 13.8538 10 13.8545C9.71667 13.8545 9.479 13.9505 9.287 14.1425C9.095 14.3345 8.99933 14.5718 9 14.8545C9 15.1378 9.096 15.3755 9.288 15.5675C9.48 15.7595 9.71733 15.8552 10 15.8545ZM9 11.8545H11V5.85449H9V11.8545ZM10 20.8545C8.61667 20.8545 7.31667 20.5918 6.1 20.0665C4.88333 19.5412 3.825 18.8288 2.925 17.9295C2.025 17.0295 1.31267 15.9712 0.788 14.7545C0.263333 13.5378 0.000666667 12.2378 0 10.8545C0 9.47116 0.262667 8.17116 0.788 6.95449C1.31333 5.73783 2.02567 4.67949 2.925 3.77949C3.825 2.87949 4.88333 2.16716 6.1 1.64249C7.31667 1.11783 8.61667 0.855159 10 0.854492C11.3833 0.854492 12.6833 1.11716 13.9 1.64249C15.1167 2.16783 16.175 2.88016 17.075 3.77949C17.975 4.67949 18.6877 5.73783 19.213 6.95449C19.7383 8.17116 20.0007 9.47116 20 10.8545C20 12.2378 19.7373 13.5378 19.212 14.7545C18.6867 15.9712 17.9743 17.0295 17.075 17.9295C16.175 18.8295 15.1167 19.5422 13.9 20.0675C12.6833 20.5928 11.3833 20.8552 10 20.8545Z' fill='%23FF2200'/%3E%3C/svg%3E%0A");
        @apply leading-none;
    }
    .drm-newsletter & {
        @apply bg-white/80 px-2 py-1 text-sm text-red md:absolute md:left-0 md:top-[calc(100%_+_0.4rem)] md:mt-0;
    }
    .drm-newsletter:has(&) {
        @apply pb-12;
    }
}
