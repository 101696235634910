@font-face {
    font-family: 'Inter';
}

@tailwind base;
@tailwind components;
@tailwind utilities;

// @import 'base/reset';
@import 'base/variables';
@import 'base/scaffolding';

@import "elements/all";
@import "components/all";
