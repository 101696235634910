.select {
    @apply relative;
    &:after {
        content: "";
        @apply pointer-events-none absolute right-4 top-1/2 h-0 w-0 -translate-y-1/2;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid theme("colors.jet");
    }
}

select {
    @apply w-full min-w-[200px] appearance-none rounded-xl border border-jet bg-white p-4 pr-10 text-jet transition-all;
    @screen md {
        @apply py-4 pl-5 pr-10;
    }
}
