.circle-background {
    @apply relative overflow-hidden;

    &:before {
        content: "";
        overflow-y: hidden;
        position: absolute;
        height: 100%;
        width: 120vw;
        transform: translateY(-50%);
        background-image: url("../img/circle-pattern.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        z-index: -1;
        left: -10vw;
        top: 50%;
    }

    &--25 {
        &:before {
            transform: translateY(-50%) rotate(-25deg);
            top: 50%;
        }
    }
}
