// #tabsContainer {
//     display: inline-block;
//     width: 300px;
//     border: 1px solid lightgrey;
// }

// [role="tablist"] {
//     // display: grid;
//     // /* 3 tabs therefore 3 equal-width columns */
//     // grid-template-columns: repeat(3, 1fr);
//     // padding-top: 4px;
//     // box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
// }

[role="tab"] {
    @apply relative;
    &:after {
        @apply pointer-events-none absolute inset-x-0 -bottom-[7px] h-[5px] rounded-full bg-malachite opacity-0 transition-opacity content-[''];
        &[aria-selected="true"] {
            @apply opacity-100;
        }
    }
}

[role="tabpanel"] {
    @apply text-xl;
}
